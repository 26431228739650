import { useCallback, useEffect, useState } from 'react';

import {
    Button,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spinner,
    useToast,
    Text,
    FormControl,
    FormLabel,
    Input,
    InputLeftElement,
    InputGroup,
    FormHelperText,
    FormErrorMessage,
} from '@chakra-ui/react';

import { get2FA, verify2FA } from '../utils/api';

import { EmailIcon } from '@chakra-ui/icons';

import MKYAlert from '../components/alert';
import { useDispatch, useSelector } from 'react-redux';
import { onSettingsChange } from '../redux/Features/Settings';
import { onChange } from '../redux/Features/Survey';

const TwoFAModal = ({ initialRef, onClose, onOpen }) => {
    const dispatch = useDispatch();
    const settingsStore = useSelector((store) => store.settings);
    const surveyStore = useSelector((store) => store.survey);

    const [TwoFAHead, setTwoFAHead] = useState('Email'); // Email | 2FA Code
    const [TwoFAState, setTwoFAState] = useState(settingsStore.TwoFAState); // Email | 2FA Code

    const [alertMsg, setAlertMsg] = useState(false);
    const [alertType, setAlertType] = useState(false);

    const toast = useToast();

    const [isError, setIsError] = useState(false);

    // useEffect(() => {
    //     // if they have an ID, auto authorize them!
    //     if (settingsStore.ID) {
    //         dispatch(
    //             onSettingsChange({
    //                 name: 'Authorized',
    //                 value: true,
    //             })
    //         );
    //     }
    // }, []);

    useEffect(() => {
        if (TwoFAState === '2FA Code') {
            setAlertType('success');
            setAlertMsg('Your Code has been sent to your email!');
        }
    }, [TwoFAState]);

    useEffect(() => {
        if (settingsStore.Authorized) {
            toast({
                title: 'Authenticated!',
                description: 'Please enjoy your stay!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            switch (settingsStore.ModalFrom) {
                case 'Profile':
                    dispatch(
                        onSettingsChange({
                            name: 'ModalType',
                            value: 'Profile',
                        })
                    );
                    break;
                default:
                    console.log(
                        '2FA: No valid place to go after, "from" value is: ',
                        settingsStore.ModalFrom
                    );
            }
        }
    }, [settingsStore.Authorized]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsError(false);
        const value = document.getElementById('2fa-email').value;

        if (
            !value ||
            (TwoFAState === 'Email' &&
                !String(value)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ))
        ) {
            setIsError(true);
            return;
        }

        setAlertMsg('Pending');
        setAlertType('warning');

        try {
            if (TwoFAState === 'Email') {
                let res = await get2FA(value).then((res) => {
                    if (!res) throw new Error('2FA Email Send Fail.');

                    if (!res.data.data.send2FACode.ID)
                        throw new Error("Email Doesn't Exist.");

                    dispatch(onChange({ name: 'Email', value }));

                    dispatch(
                        onSettingsChange({
                            name: 'TwoFAState',
                            value: '2FA Code',
                        })
                    );

                    if (TwoFAState === 'Email') setTwoFAState('2FA Code');
                });
            } else if (TwoFAState === '2FA Code') {
                let res = await verify2FA(
                    settingsStore.ID,
                    surveyStore.Email,
                    value
                ).then((res) => {
                    if (!res) throw new Error('Confiriming 2FA Failed.');

                    if (res.data.data.verify2FACode.Message !== 'Success!')
                        throw new Error('2FA Verification Failed.');

                    setAlertType('success');
                    setAlertMsg('You are authorized!');
                    dispatch(
                        onSettingsChange({
                            name: 'ID',
                            value: res.data.data.send2FACode.ID,
                        })
                    );
                    dispatch(
                        onSettingsChange({
                            name: 'Authorized',
                            value: true,
                        })
                    );
                    dispatch(
                        onChange({
                            name: 'FirstName',
                            value: res.data.data.verify2FACode.FirstName,
                        })
                    );
                    dispatch(
                        onChange({
                            name: 'LastName',
                            value: res.data.data.verify2FACode.LastName,
                        })
                    );
                    if (
                        (surveyStore.Email.includes('@marketocracymasters') &&
                            (surveyStore.Email.includes('tony') ||
                                surveyStore.Email.includes('glen'))) ||
                        surveyStore.Email.includes('thegimreper@gmail.com') ||
                        surveyStore.Email.includes(
                            'trevor.kamin@studentprogrammers.net'
                        )
                    ) {
                        dispatch(
                            onSettingsChange({
                                name: 'AccessLevel',
                                value: 'manager',
                            })
                        );
                    }
                });
            }
        } catch (e) {
            console.error('2FA get', e);
            setAlertMsg(`2FA ${e}`);
            setAlertType('error');
        }
    };

    const TwoFAHeader = useCallback(() => <>Profile - Sign In</>, []);

    const TwoFABody = () => {
        return (
            <>
                <FormControl isInvalid={isError}>
                    {alertMsg ? (
                        <MKYAlert
                            type={alertType}
                            title={alertMsg}
                            styles={{ margin: '0 0 1rem' }}
                        />
                    ) : (
                        <></>
                    )}
                    <FormLabel>
                        {TwoFAState === '2FA Code' ? '' : TwoFAState}
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<EmailIcon color="gray.300" />}
                        />
                        <Input
                            id="2fa-email"
                            type="email"
                            ref={initialRef}
                            placeholder={
                                TwoFAState === 'Email'
                                    ? 'you@example.com'
                                    : 'Code in Email'
                            }
                        />
                    </InputGroup>
                    {!isError ? (
                        <FormHelperText color="mkytwo.300">
                            Please enter{' '}
                            {TwoFAState === 'Email'
                                ? `your email to proceed.`
                                : 'the code sent to your email to continue.'}
                        </FormHelperText>
                    ) : (
                        <FormErrorMessage>Email is required.</FormErrorMessage>
                    )}
                </FormControl>
            </>
        );
    };

    const TwoFAFooter = () => {
        return (
            <>
                <Button
                    onClick={() => {
                        if (TwoFAState === 'Email') {
                            return onClose();
                        }
                        dispatch(onSettingsChange({ name: 'ID', value: '' }));
                        dispatch(
                            onSettingsChange({
                                name: 'TwoFAState',
                                value: 'Email',
                            })
                        );
                        dispatch(onChange({ name: 'Email', value: '' }));
                        setAlertMsg(false);
                        setAlertType(false);
                        setTwoFAState('Email');
                    }}
                    colorScheme={TwoFAState === 'Email' ? 'gray' : 'red'}
                >
                    {TwoFAState === 'Email' ? 'Cancel' : 'Restart'}
                </Button>{' '}
                <Button onClick={onSubmit} colorScheme="mkytwo" mr={3}>
                    {alertType !== 'warning' ? 'Submit' : <Spinner />}
                </Button>
            </>
        );
    };

    return (
        <>
            <ModalHeader>
                <TwoFAHeader />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <TwoFABody />
            </ModalBody>

            <ModalFooter>
                <TwoFAFooter />
            </ModalFooter>
        </>
    );
};

export default TwoFAModal;

// export { TwoFAHeader, TwoFABody, TwoFAFooter };
