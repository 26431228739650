import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    useColorMode,
    Image,
    Tooltip,
} from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';

import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { BsSun, BsMoonStarsFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { onSettingsChange } from '../redux/Features/Settings';

// import SpecializedModal from './modalPages/ModalSkeleton';

export default function WithSubnavigation({ onModalOpen }) {
    const { isOpen, onToggle } = useDisclosure();
    const dispatch = useDispatch();

    const settingsStore = useSelector((state) => state.settings);

    return (
        <Box background={'mkyfive.50'}>
            <Flex
                // color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 5 }}
                px={{ base: 4, xl: '8%' }}
                // px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
            >
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}
                    order={2}
                    justifyContent="flex-end"
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 3, sm: 6, md: 2 }}>
                    <a href={'/'}>
                        <Image
                            maxW={{
                                base: '270px',
                                sm: '300px',
                                lg: '500px',
                            }}
                            alt="Marketocracy Masters Logo"
                            src={
                                'https://marketocracy-front-end.s3.us-east-2.amazonaws.com/logos/mky-logo.png'
                            }
                        />
                    </a>
                </Flex>

                <Flex
                    display={{ base: 'none', md: 'flex' }}
                    //   justify={{ base: 'center', md: 'end' }}
                    ml={10}
                    mb={1}
                    mr={3}
                >
                    <DesktopNav />
                </Flex>

                <Stack
                    display={{ base: 'none', md: 'flex' }}
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}
                >
                    <Tooltip
                        hasArrow
                        label="Click here to view accounts that you already have with us."
                        bg="mkyone.900"
                    >
                        <Button
                            as={'a'}
                            fontSize={'sm'}
                            fontWeight={400}
                            variant={'solid'}
                            rounded={'full'}
                            bg="mkytwo.500"
                            color="mkyfive.50"
                            border="1px solid"
                            borderColor="mkytwo.500"
                            _hover={{
                                cursor: 'pointer',
                                boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                                bg: 'mkyfive.50',
                                color: 'mkytwo.500',
                            }}
                            onClick={() =>
                                window.open(
                                    'https://ndcdyn.interactivebrokers.com/sso/Login?partnerID=2M0M21',
                                    '_blank'
                                )
                            }
                        >
                            My Account(s)
                        </Button>
                    </Tooltip>
                    <Tooltip
                        hasArrow
                        label="Click here to view or change your allocations or to update your investment profile."
                        bg="mkyone.900"
                    >
                        <Button
                            as={'a'}
                            fontSize={'sm'}
                            fontWeight={400}
                            variant={'solid'}
                            rounded={'full'}
                            bg="mkytwo.500"
                            color="mkyfive.50"
                            border="1px solid"
                            borderColor="mkytwo.500"
                            _hover={{
                                cursor: 'pointer',
                                boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                                bg: 'mkyfive.50',
                                color: 'mkytwo.500',
                            }}
                            onClick={() => {
                                dispatch(
                                    onSettingsChange({
                                        name: 'ModalFrom',
                                        value: 'Profile',
                                    })
                                );
                                dispatch(
                                    onSettingsChange({
                                        name: 'ModalType',
                                        value: 'TwoFA',
                                    })
                                );
                                return onModalOpen();
                            }}
                        >
                            My Profile
                        </Button>
                    </Tooltip>
                    {settingsStore.AccessLevel === 'manager' ? (
                        <Button
                            as={'a'}
                            fontSize={'sm'}
                            fontWeight={400}
                            variant={'solid'}
                            href={'/dashboard'}
                            rounded={'full'}
                            bg="mkytwo.500"
                            cursor={'pointer'}
                            color="mkyfive.50"
                            _hover={{
                                bg: 'mkyfive.50',
                                color: 'mkytwo.500',
                                boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                            }}
                            mb={3}
                        >
                            Dashboard
                        </Button>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav onModalOpen={onModalOpen} />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('mkyfive.900', 'mkyfive.100');
    const linkHoverColor = useColorModeValue('mkytwo.700', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                as={RouterLink}
                                to={navItem.href}
                                href={navItem.href}
                                p={2}
                                fontSize={'md'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}
                            >
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}
                            >
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <RouterLink to={`/${child.label}`}>
                                            <DesktopSubNav
                                                key={child.label}
                                                {...child}
                                            />
                                        </RouterLink>
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
        >
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}
                    >
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{
                        opacity: '100%',
                        transform: 'translateX(0)',
                    }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                >
                    <Stack direction={'row'} align={'center'}>
                        <Box>
                            <Text
                                transition={'all .3s ease'}
                                _groupHover={{ color: 'pink.400' }}
                                fontWeight={500}
                            >
                                {label}
                            </Text>
                            <Text fontSize={'sm'}>{subLabel}</Text>
                        </Box>
                        <Flex
                            transition={'all .3s ease'}
                            transform={'translateX(-10px)'}
                            opacity={0}
                            _groupHover={{
                                opacity: '100%',
                                transform: 'translateX(0)',
                            }}
                            justify={'flex-end'}
                            align={'center'}
                            flex={1}
                        >
                            <Icon
                                color={'pink.400'}
                                w={5}
                                h={5}
                                as={ChevronRightIcon}
                            />
                        </Flex>
                    </Stack>
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = ({ onModalOpen }) => {
    const dispatch = useDispatch();
    const settingsStore = useSelector((state) => state.settings);

    return (
        <Stack
            bg={useColorModeValue('mkyfive.50', 'gray.800')}
            p={4}
            display={{ md: 'none' }}
        >
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
            <Flex
                direction={'column'}
                display={{ md: 'none', base: 'flex' }}
                gap={3}
            >
                <Button
                    as={'a'}
                    fontSize={'sm'}
                    fontWeight={400}
                    variant={'solid'}
                    cursor={'pointer'}
                    rounded={'full'}
                    bg="mkytwo.500"
                    color="mkyfive.50"
                    _hover={{
                        bg: 'mkyfive.50',
                        color: 'mkytwo.500',
                        boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                    }}
                    onClick={() =>
                        window.open(
                            'https://ndcdyn.interactivebrokers.com/sso/Login?partnerID=2M0M21',
                            '_blank'
                        )
                    }
                >
                    My Investments
                </Button>
                <Button
                    as={'a'}
                    fontSize={'sm'}
                    fontWeight={600}
                    variant={'solid'}
                    bg="mkyfive.50"
                    color="mkytwo.500"
                    border="2px"
                    colorScheme="mkytwo.500"
                    cursor={'pointer'}
                    _hover={{
                        bg: 'mkytwo.500',
                        color: 'mkyfive.50',
                    }}
                    rounded={'full'}
                    onClick={() => {
                        dispatch(
                            onSettingsChange({
                                name: 'ModalFrom',
                                value: 'Profile',
                            })
                        );
                        dispatch(
                            onSettingsChange({
                                name: 'ModalType',
                                value: 'TwoFA',
                            })
                        );
                        return onModalOpen();
                    }}
                >
                    My Profile
                </Button>
                {settingsStore.AccessLevel === 'manager' ? (
                    <Button
                        as={'a'}
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'solid'}
                        href={'/dashboard'}
                        rounded={'full'}
                        bg="mkytwo.500"
                        cursor={'pointer'}
                        color="mkyfive.50"
                        _hover={{
                            bg: 'mkyfive.50',
                            color: 'mkytwo.500',
                            boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                        }}
                        mb={3}
                    >
                        Dashboard
                    </Button>
                ) : (
                    <></>
                )}
            </Flex>
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}
                >
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse
                in={isOpen}
                animateOpacity
                style={{ marginTop: '0!important' }}
            >
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                >
                    {children &&
                        children.map((child) => (
                            <Link
                                as={RouterLink}
                                to={child.href}
                                href={child.href}
                                key={child.label}
                                py={2}
                            >
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};
const NAV_ITEMS = [
    {
        label: 'Home',
        href: '/',
    },
    {
        label: 'About',
        href: '/about',
    },
    {
        label: 'Investing',
        href: '/investments',
    },
];
